<template>
  <div class="bill">
    <nav-bar :title="$t('账单记录')" />

    <div class="select" @click="showDate = true">
      <div>{{ formatDate(date) }}</div>
      <img src="@/assets/img/base/more.png" class="more-icon" />
    </div>

    <div class="list">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false" offset="10"
        @load="onLoad">
        <div class="bill-item" v-for="item in list" :key="item.id">
          <div class="bill-cell">
            <div>{{ $t(item.bname) }}</div>
            <div>{{ item.bdate }}</div>
          </div>
          <div class="bill-cell">
            <div>{{ item.cname }}</div>
            <div style="color: green;" v-if="item.amount > 0">+{{ item.amount }}</div>
            <div style="color: #e02020;" v-else>{{ item.amount }}</div>
          </div>
          <div class="bill-bz" v-if="item.bz">
            {{ item.lx == 3 ? abbr(item.bz, 4, 4) : $t(item.bz) }}
          </div>
        </div>
      </van-list>
      <empty :text="$t('暂无记录')" v-if="list.length == 0" />
    </div>

    <div class="cp-popup">
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker :confirm-button-text="$t('确定')" :cancel-button-text="$t('取消')" :columns="filterList"
          @cancel="showPicker = false" @confirm="onConfirm" />
      </van-popup>

      <van-popup v-model:show="showDate" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        @confirm="dateConfirm"
        @cancel="showDate = false"
      />
    </van-popup>
    </div>
  </div>
</template>

<script>
import Empty from '@/components/Empty'
import { ref } from 'vue'
import CustomComponent from "@/components/CustomComponent";

import { List, Popup, Picker, DatetimePicker } from "vant";

export default {
  name: 'Bill',
  components: {
    Empty,
    [List.name]: List,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      allList: ref([]),
      loading: ref(false),
      finished: ref(false),
      showPicker: ref(false),
      filterList: ref([]),

      date: ref(""),
      showDate: ref(false),
      currentDate: ref(""),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.date = new Date()
    this.currentDate = new Date()
    this.load()
    this.getdata()
  },
  methods: {
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    load() {
      // this.filterList = [
      //   { text: this.$t('Bill.all'), val: 99 },
      //   { text: this.$t('Bill.shouyi'), val: 0 },
      //   { text: this.$t('Bill.chongzhi'), val: 1 },
      //   { text: this.$t('Bill.tixian'), val: 2 },
      //   { text: this.$t('Bill.zhuanzhang'), val: 3 },
      //   { text: this.$t('Bill.zhuanhuan'), val: 4 },
      //   { text: this.$t('Bill.zengjian'), val: 5 },
      //   { text: this.$t('Bill.xiaofei'), val: 6 }
      // ]
    },
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    back() {
      this.$router.back()
    },
    onConfirm(e) {
      if (e.val == 99) {
        this.list = this.allList
      } else {
        this.list = this.allList.filter(item => {
          return item.lx == e.val
        })
      }
      this.showPicker = false
    },
    formatDate(date) {
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      return y + '-' + m
    },
    dateConfirm() {
      this.date = this.currentDate
      this.showDate = false
      this.pageIndex = 1
      this.list = []
      this.getdata()
    },
    getdata() {
      let _this = this
      let year = this.date.getFullYear()
      let month = this.date.getMonth() + 1
      _this.$request.post(
        "api/Bill/List",
        {
          userid: _this.us.userid,
          uid: _this.us.id,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          cid: 99,
          year: year,
          month: month
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
          _this.allList.push(...data)
        }
      )
    }
  }
}
</script>

<style scoped>
.back-wrap {
  padding: 0 12px;
}

.list {
  padding: 10px 10px 0;
}

.list-name {
  display: flex;
}

.list-name>div {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-name img {
  margin-left: 8px;
  width: 14px;
}

.list-val {
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.list-val>div {
  flex: 1;
}

.bill-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #121C2E;
}

.bill-cell {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.bill-bz {
  font-size: 13px;
  color: #aaaaaa;
  overflow-wrap: break-word;
}

.cp-popup /deep/ .van-picker {
  background: #121C2E !important;
}

.cp-popup /deep/ .van-picker__mask {
  background-size: 100% 0 !important;
}

.cp-popup /deep/ .van-picker__frame::after {
  border-top: 1px solid rgba(85, 80, 80, 0.6);
  border-bottom: 1px solid rgba(85, 80, 80, 0.6);
}

.cp-popup /deep/ .van-picker-column__item {
  color: #fff;
}

.cp-popup /deep/ .van-picker__confirm {
  color: #884DDA;
}

.cp-popup /deep/ .van-picker__title {
  color: #fff;
}

.select {
  margin: 0 10px;
  padding: 15px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #121C2E;
  color: #fff;
}

.more-icon {
  width: 14px;
}
</style>